.float-label {
  position: relative;
}

.float-label > input {
  display: flex;
  height: 62px;
  padding: 10px 20px;
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  color: #8f8f8f !important;
}

.float-label > .input-password {
  display: flex;
  height: 62px;
  padding: 10px 20px;
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

.input-password {
  color: red !important;
}

.label {
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 20px;
  top: 18px;
  transition: 0.2s ease all;
  z-index: 1000;
}

.as-placeholder {
  color: #9b9b9b;
}

.as-label {
  top: -10px;
  font-size: 14px !important;
  color: #40a9ff;
  background: #fff;
  padding: 0 8px;
  margin-left: -8px;
}

.ant-form-item-with-help .ant-form-item-explain {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
